import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';

import { CONFIG } from '../config/constant';

if (!firebase.apps.length) {
  firebase.initializeApp(CONFIG.firebase);
}

export default firebase;
