
let menuItems = {
  items: [
    {
      id: 'navigation',
      title: 'Navigation',
      type: 'group',
      icon: 'icon-navigation',
      children: [
        {
          id: 'dashboard',
          title: 'Dashboard',
          type: 'item',
          url: '/app/dashboard/default',
          icon: 'feather icon-home',
        }
      ]
    },
    {
      id: 'Products-Categories',
      title: 'Products & Categories',
      type: 'group',
      icon: 'icon-group',
      children: [
        {
          id: 'Categories',
          title: 'Categories',
          type: 'item',
          url: '/categories',
          icon: 'feather icon-file-text',
        },
        {
          id: 'Products',
          title: 'Products',
          type: 'item',
          url: '/products',
          icon: 'feather icon-server',
        }
      ]
    },
    {
      id: 'employees',
      title: 'Employees',
      type: 'group',
      icon: 'icon-group',
      children: [
        {
          id: 'Employees',
          title: 'Employees',
          type: 'item',
          url: '/employees',
          icon: 'feather icon-file-text',
        }
      ]
    },
  ]
};

export default menuItems;
