import React, { useContext, useState, useEffect } from 'react';
import { ListGroup, Dropdown, Media } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";

import { ConfigContext } from '../../../../contexts/ConfigContext';
import useAuth from '../../../../hooks/useAuth';

import avatar1 from '../../../../assets/images/user/avatar-1.jpg';
import firebase from '../../../../firebase/lib';

const NavRight = () => {
  const configContext = useContext(ConfigContext);
  const history = useHistory();
  const { logout } = useAuth();
  let user = useAuth().user;
  const { rtlLayout } = configContext.state;
  const [userProfile, setUserProfile] = useState();
  if (!user) {
    user = {
      email: "",
      id: ""
    }
  }

  useEffect(() => {
    //get data
    let userDir = user.email.replace(/\.|@/g, '');
    let firebaseObj = firebase.database().ref('Data/' + userDir + '/Profile');
    firebaseObj.on('value', (snapshot) => {
      if (snapshot.exists()) {
        setUserProfile(snapshot.val().url)
      }
    });
  }, [])

  const handleLogout = async () => {
    try {
      //handleClose();
      await logout();
      history.push('/auth/signin');
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <React.Fragment>
      <ListGroup as="ul" bsPrefix=" " className="navbar-nav ml-auto" id="navbar-right">

        <ListGroup.Item as="li" bsPrefix=" ">
          <Dropdown alignRight={!rtlLayout} className="drp-user">
            <Dropdown.Toggle as={Link} variant="link" to="#" id="dropdown-basic">
              <i className="icon feather icon-user" />
            </Dropdown.Toggle>
            <Dropdown.Menu alignRight className="profile-notification">
              <div className="pro-head">
                <img src={userProfile} className="img-radius" alt="User Profile" />
                <span>{user.email}</span>
                <Link to="#" className="dud-logout" title="Logout">
                  <i className="feather icon-log-out" />
                </Link>
              </div>
              <ListGroup as="ul" bsPrefix=" " variant="flush" className="pro-body">
                {/* <ListGroup.Item as="li" bsPrefix=" ">
                  <Link to="#" className="dropdown-item">
                    <i className="feather icon-user" /> Profile
                  </Link>
                </ListGroup.Item> */}
                <ListGroup.Item as="li" bsPrefix=" ">
                  <Link to="#" className="dropdown-item" onClick={handleLogout}>
                    <i className="feather icon-log-out" /> Logout
                  </Link>
                </ListGroup.Item>
              </ListGroup>
            </Dropdown.Menu>
          </Dropdown>
        </ListGroup.Item>
      </ListGroup>
    </React.Fragment>
  );
};

export default NavRight;
